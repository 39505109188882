$hsVideo_x: 36.5vw;
$hsVideo_y: 41vh;
$hsOne_x: 60.5vw;
$hsOne_y: 37vh;
$hsTwo_x: 55vw;
$hsTwo_y: 58vh;
$hsThree_x: 28vw;
$hsThree_y: 62vh;

.visible {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s linear;
}

.unvisible {
  @extend .visible;
  opacity: 0;
}

.title {
  position: absolute;
  width: 30rem;
  height: 5rem;
  font-size: 6rem;
  font-weight: 900;
  color: var(--white-color);
  left: 9rem;
  bottom: 1rem;
  z-index: 3;
  background: left / contain no-repeat url("./../assets/Milking.png");
}

.hsVideo, .hsOne, .hsTwo, .hsThree {
  position: absolute;
  width: 3.3rem;
  height: 3.3rem;   
  z-index: 6; 
  &:hover {
    // cursor: pointer;
  }
}

.hsVideo {
  background: left / auto 100% no-repeat url("./../assets/video.png");
  left: $hsVideo_x;
  top: $hsVideo_y;
  transition: width 0.4s linear;
  overflow: hidden;
  &:hover {    
    width: 15rem;
  }
  div {
    width: 15rem;
    height: 3.3rem;
    background: left / auto 100% no-repeat url("./../assets/Video_Hover_State_1.png");
    opacity: 0;
    transition-delay: 0.38s;
    &:hover {
      opacity: 1;
      transition-delay: 1ms;
    }
  } 
}

.hsOne { 
  background: center / cover no-repeat url("./../assets/Static\ i\ Icon.png");
  left: $hsOne_x;
  top: $hsOne_y;
  &:hover {
    background: center / cover no-repeat url("./../assets/Hover\ i\ Icon.png");
  }  
}

.hsTwo { 
  background: center / cover no-repeat url("./../assets/Static\ i\ Icon.png");
  left: $hsTwo_x;
  top: $hsTwo_y; 
  &:hover {
    background: center / cover no-repeat url("./../assets/Hover\ i\ Icon.png");
  }
}

.hsThree { 
  background: center / cover no-repeat url("./../assets/Static\ i\ Icon.png");
  left: $hsThree_x;
  top: $hsThree_y;
  &:hover {
    background: center / cover no-repeat url("./../assets/Hover\ i\ Icon.png");
  }
}

@media (max-width: 800px) {
  .title {
    width: 25rem;
    height: 4rem;
    font-size: 5rem;
    left: 7rem;
  }
}