.container {  
  position: fixed;
  width: 100%;  
  height: 100%;
  overflow: hidden;
}

.prevContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%; 
  background: center/cover no-repeat url('./../assets/DairyMax_Final.jpg');
  background-position: 50% 50%; 
  z-index: 0;
}
.logoContainer {
  position: absolute;
  width: 100%;
  height: 7rem;
  top: 1rem;
  left: 0;
  z-index: 2;
}

.logo {
  position: absolute;
  width: 10rem;
  height: 7rem;
  background: right / cover no-repeat url('./../assets/Logo.png');
  top: 0;
  left: 0;
  z-index: 2;
}

.mainText {
  position: absolute;
  left: 8rem;
  font-size: 2.2rem;
  top: 1.75rem;
}

.mainChildContainer {
  position: relative;
  height: 10vh;
}

.mainNewImage {
  position: absolute;
  object-fit: cover;
  left: -8.1rem;
  width: 44rem !important;
}

.mainButtonTour {
  position: absolute;
  width: 9rem;
  height: 2rem;
  left: 25.5rem;
  top: 1.75rem;
  background: center/contain no-repeat
    url('../assets/Back\ to\ Explore\ Static.png');
  display: flex;
  align-items: center;
  justify-content: center;

  .buttonText {
    color: var(--white-color);
    font-size: 1rem;
    position: absolute;    
  }

  &:hover {
    background-image: url('../assets/Back_to_Explore_Hover1.png');
  }
}

.playAll,
.playAllFrame,
.home {
  position: absolute;
  width: 8rem;
  height: 2.5rem;  
  right: 2.2rem;
  top: 3.5rem;
  z-index: 2;
}

.home {
  width: 2.5rem;
  right: 4rem;
  transition: width 0.3s linear;
  background: right / auto 100% no-repeat url('./../assets/Back\ Static.png');
  overflow: hidden;
  &:hover {
    width: 7rem;   
  }
  div {
    position: absolute;
    right: 0;
    top: 0;
    width: 7rem;
    height: 2.5rem;
    background: right / auto 100% no-repeat url('./../assets/Back\ Hover.png');
    opacity: 0;
    transition-delay: 0.28s;
    &:hover {
      opacity: 1;
      transition-delay: 1ms;
    }
  }
}

.socialMedia {
  position: absolute;
  width: 1.6rem;
  height: 11.5rem;
  right: 2rem;
  bottom: 2rem;
  z-index: 1;
}

.tel,
.inst,
.tw,
.fb,
.dd {
  width: 1.6rem;
  height: 1.6rem;
}

.tel {
  background: center/ contain no-repeat url('./../assets/Email.png');
  &:hover {   
    background-image: url('./../assets/Email Hover.png');
  }
}

.inst {
  margin-top: 1rem;
  background: center/ contain no-repeat url('./../assets/Instagram.png');
  &:hover {  
    background-image: url('./../assets/Instagram Hover.png');
  }
}

.tw {
  margin-top: 1rem;
  background: center/ contain no-repeat url('./../assets/Twitter.png');
  &:hover {   
    background-image: url('./../assets/Twitter Hover.png');
  }
}

.fb {
  margin-top: 1rem;
  background: center/ contain no-repeat url('./../assets/Facebook.png');
  &:hover {   
    background-image: url('./../assets/Facebook Hover.png');
  }
}

.dd {
  margin-top: 1rem;
  border: 1 px solid red; 
  background: center/ contain no-repeat url('./../assets/DDZ-btn.png'); 
  &:hover {   
    background-image: url('./../assets/DDZ-btn-hover.png');
  }
}

.warning {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #6168a1;
  opacity: 0.9;
  z-index: 800;
  overflow: hidden;

  .phoneWarn {
    position: absolute;
    width: 70%;
    left: calc(50% - 35%);
    top: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .phoneWarnImg {  
    width: 60%;   
  }

  .phoneWarnText {
    margin-top: 2.5rem;
    width: 100%;
    font-size: 1.6rem;
    color: var(--white-color);
    display: flex;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .logo {
    width: 8.5rem;
    height: 6.5rem;
  }

  .playAll,
  .home {
    top: 2rem;
  }

  .mainText {
    position: absolute;
    left: 9.5rem;
    font-size: 2rem;
    top: 2.2rem;
  }
}

@media (max-width: 600px) {
  .socialMedia {
    bottom: 9.5rem;
  }
}

@media (max-width: 500px) {
  .mainChildContainer {
    height: 8vh;
  }

  .mainNewImage {
    width: 40rem !important;
  }

  .mainButtonTour {
    width: 8.2rem;
    height: 1.9rem;
    left: 24rem;
    top: 1.5rem;
    h1 {
      font-size: 0.8rem;
      top: 0.6rem;
      left: 0.9rem;
    }
  }

  .mainText {
    left: 8rem;
    font-size: 1.8rem;
    top: 1.8rem;
  }
}
