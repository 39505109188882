.container {
  position: absolute;
  width: 28rem;
  height: 20rem;
  top: calc(50% - 10rem);
  left: calc(50% - 14rem);
  z-index: 10;
  background: center/contain no-repeat url('./../assets/Share Popup.png');
  display: flex;
  flex-direction: column; 
  align-items: center;
  padding: 1.2rem 1.5rem;
  opacity: 0;
  transition: opacity 0.6s linear
}

.openContainer {
  @extend .container;
  opacity: 1;
}

.icon {
  width: 5rem;
  height: 5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.title {
  padding-top: 1.2rem;
  font-size: 2.4rem;
  font-weight: 900;
  color: var(--black-color);
}

.text {
  padding-top: 0.8rem;
  color: var(--black-color);
  font-size: 1.2rem;
  text-align: center;
}

.button { 
  margin-top: 1.5rem;
  width: 8.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: var(--white-color);
  background: center/ contain no-repeat
    url('./../assets/Share Button Static.png');
  &:hover {
    background: center/ contain no-repeat
      url('./../assets/Share Button Hover.png');
    // cursor: pointer;
  }
}

.close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 1.4rem;
  top: 1.2rem;
  z-index: 2;
  background: center / cover no-repeat url('./../assets/X_Icon.png');
}
