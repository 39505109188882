@font-face {
  font-family: "Filson";
  src: url("/src/assets/fonts/FilsonSoftHeavy.ttf");
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: "Filson";
  src: url("/src/assets/fonts/FilsonProBook.ttf");
  font-weight: 300;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  --green-color: #a2c64b;
  --yellow-color: #eec331;
  --red-color: #c81e4a;
  --blue-color: #32348e;
  --light-blue: #00ADEF;
  --white-color: #ffffff;
  --grey-color: #818181;
  --black-color: #000000;
  font-size: 20px;
  cursor: none;
  /* cursor: url(./cursor.gif), auto; */
}

body {
  position: fixed;
  margin: 0;
  font-family: "Filson", sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  

  touch-action: pan-x pan-y;

  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

#root{ 
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 1600px) {
  html {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 1000px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 12px;
  }
}
