$hsSustain_x: 34vw;
$hsSustain_y: 55vh;
$hsFeeding_x: 31vw;
$hsFeeding_y: 24vh;
$hsHousing_x: 55vw;
$hsHousing_y: 29vh;
$hsMilking_x: 63vw;
$hsMilking_y: 38vh;
$hsTransport_x: 59vw;
$hsTransport_y: 53vh;

.visible {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s linear;
}

.unvisible {
  @extend .visible;
  opacity: 0;
}

.title {
  position: absolute;
  width: 58rem;
  height: 5.5rem;
  font-size: 4.8rem;
  font-weight: 900;
  color: var(--white-color);
  left: calc(50% - 27rem);
  bottom: 0;
  z-index: 3;
}

.hsSustain,
.hsFeeding,
.hsFeeding,
.hsHousing,
.hsMilking,
.hsTransport,
.hsSustain_green,
.hsFeeding_green,
.hsHousing_green,
.hsMilking_green,
.hsTransport_green {
  position: absolute;
  width: 4.3rem;
  height: 3.3rem;
  z-index: 6;
  transition: width 0.4s linear;
}

.innerDiv {
  width: 22.5rem;
  height: 3.3rem;
  opacity: 0;
  transition: all 0.2s linear;
  transition-delay: 0.22s;
  &:hover {
    opacity: 1;
    transition-delay: 1ms;
  }
}

.hsSustain {
  background: left / auto 100% no-repeat
    url('./../assets/Sustainability\ Static.png');
  left: $hsSustain_x;
  top: $hsSustain_y;
  overflow: hidden;
  &:hover {
    width: 22.5rem;
  }
  div {
    @extend .innerDiv;
    background: left / auto 100% no-repeat
      url('./../assets/Sustainability\ Hover.png');
  }
}

.hsSustain_green {
  @extend .hsSustain;
  background-image: url('./../assets/Sustainability\ Chapter\ Completed.png');
}

.hsFeeding {
  background: left / auto 100% no-repeat url('./../assets/Feeding\ Static.png');
  left: $hsFeeding_x;
  top: $hsFeeding_y;
  overflow: hidden;
  &:hover {
    width: 22.5rem;
  }
  div {
    @extend .innerDiv;
    background: left / auto 100% no-repeat url('./../assets/Feeding\ hover.png');
  }
}
.hsFeeding_green {
  @extend .hsFeeding;
  background-image: url('./../assets/Feeding\ Chapter\ Completed.png');
}

.hsHousing {
  background: left / auto 100% no-repeat url('./../assets/Housing\ Static.png');
  left: $hsHousing_x;
  top: $hsHousing_y;
  overflow: hidden;
  &:hover {
    width: 22.5rem;
  }
  div {
    @extend .innerDiv;
    background: left / auto 100% no-repeat url('./../assets/Housing\ Hover.png');
  }
}
.hsHousing_green {
  @extend .hsHousing;
  background-image: url('./../assets/Housing\ Chapter\ Completed.png');
}

.hsMilking {
  background: left / auto 100% no-repeat url('./../assets/Milking\ Static.png');
  left: $hsMilking_x;
  top: $hsMilking_y;
  overflow: hidden;
  &:hover {
    width: 22.5rem;
  }
  div {
    @extend .innerDiv;
    background: left / auto 100% no-repeat url('./../assets/Milking\ Hover.png');
  }
}
.hsMilking_green {
  @extend .hsMilking;
  background-image: url('./../assets//Milking\ Chapter\ Completed.png');
}

.hsTransport {
  background: left / auto 100% no-repeat
    url('./../assets/Transportation\ static.png');
  left: $hsTransport_x;
  top: $hsTransport_y;
  overflow: hidden;
  &:hover {
    width: 22.5rem;
  }
  div {
    @extend .innerDiv;
    background: left / auto 100% no-repeat
      url('./../assets/Transportation\ Hover.png');
  }
}
.hsTransport_green {
  @extend .hsTransport;
  background-image: url('./../assets/Transportation\ Chapter\ Completed.png');
}

@media (max-width: 1100px) {
  .title {
    width: 52rem;
    height: 5.2rem;
    font-size: 4.4rem;
    left: calc(50% - 24rem);
  }
}

@media (max-width: 900px) {
  .title {
    width: 48rem;
    height: 4.7rem;
    font-size: 4rem;
    left: calc(50% - 22rem);
  }
}

@media (max-width: 800px) {
  .hsSustain {
    left: 27vw;
  }
  .hsFeeding {
    left: 25vw;
    top: 24vh;
  }
  .hsHousing {
    left: 52vw;
    top: 24vh;
  }
  .title {
    width: 35rem;
    height: 8rem;
    font-size: 3.7rem;
    left: calc(50% - 18rem);
    text-align: center;
  }
}

@media (max-width: 600px) {
  .title {
    width: 28rem;
    height: 8rem;
    font-size: 3.7rem;
    left: 6rem;
  }
}
