.container {
  position: absolute;
  width: 44rem;
  height: 4.6rem;
  bottom: 4rem;
  left: calc(50% - 22rem);
  z-index: 5;
  background: center / contain no-repeat
    url('./../assets//Video_Scrubber_Pop_up.png');
  opacity: 0;
  transition: opacity 0.6s linear;
}
.container_open {
  @extend .container;
  opacity: 1;
}

.container_open_mobile {
  @extend .container;
  opacity: 1;
  top: 55vh;
}

.buttons {
  position: absolute;
  width: 5rem;
  height: 2rem;
  top: 1.3rem;
  left: 2.2rem;
  z-index: 3;

  .scrubLeft,
  .scrubRight {
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0.5rem;
    z-index: 3;   
  }

  .scrubLeft {
    left: 0px;
    background: center / contain no-repeat url('./../assets/Skip_Backwards.png');  
  }
  .scrubRight {
    right: 0px;
    background: center / contain no-repeat url('./../assets/Skip_Forward.png');
  }

  .play,
  .pause {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    left: 1.5rem;
    z-index: 3;   
  }

  .play {
    background: center / contain no-repeat url('./../assets/Pause.png');
  }

  .pause {
    background: center / contain no-repeat url('./../assets/PlayButton.png');
  }
}

.containerBar {
  position: absolute;
  width: 33.5rem;
  height: 0.2rem;
  top: 2.1rem;
  right: 1.8rem;
  z-index: 3;

  .bar {
    position: absolute;
    width: 100%;
    height: 0.3rem;
    top: 0.1rem;
    left: 0;
    z-index: 2; 
    border-radius: 0.1rem;
    visibility: hidden;
    cursor: none;
  }

  .circle {
    position: absolute;  
    width: 100%;  
    height: 0.3rem; 
    left: 0;
    z-index: 3;
    background-color: var(--white-color);
    border-radius: 0.3rem;
    transition: all 1s linear;   
    cursor: none;
  }
  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    cursor: none;
    background: transparent;   
    width: 100%;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background: var(--blue-color);
    height: 0.2rem;
    top: 0.1rem;
    border-radius: 0.1rem;
  }
 
  input[type='range']::-moz-range-track {
    background: var(--blue-color);
    height: 0.3rem;
    top: 0.1rem;
    border-radius: 0.1rem;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -0.3rem;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    background: var(--blue-color);
  }

  input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -0.3rem;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    background: var(--blue-color);    
  }
}

.minutes {
  position: absolute;
  width: 7rem;
  height: 1.1rem;
  display: flex;
  justify-content: flex-end;
  top: 1rem;
  right: 1.8rem;
  z-index: 3;
  color: var(--blue-color);
  font-size: 0.7rem;
  font-weight: 300;
}

@media (max-width: 1000px) {
  .container {
    width: 34rem;
    left: calc(50% - 17rem);
  }
  .containerBar {
    width: 24rem;
  }
  .buttons {
    left: 1.7rem;
  }
  .scrubLeft,
  .scrubRight {
    width: 1rem;
    height: 1rem;
    top: 0.5rem;
  }
  .minutes {
    height: 1.3rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 500px) {
  .container {
    width: 26rem;
    left: calc(50% - 13rem);
  }
  .containerBar {
    width: 16rem;
  }
  .buttons {
    left: 1.7rem;
  }
  .scrubLeft,
  .scrubRight {
    width: 1rem;
    height: 1rem;
    top: 0.5rem;
  }
  .minutes {
    width: 6rem;
    height: 1.3rem;
    font-size: 0.9rem;
  }
}
