.butterflyContainer {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  left: 5%;
  top: 60%;
  z-index: 5;
  transform: rotate(0deg);
  transition: all 2s linear;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.container { 
  position: absolute;
  z-index: 50;
  width: 2rem;
  height: 2rem;
  pointer-events: none;
  transform: translate(-50%, -50%);
  background-image: url('../assets/ButterflyStateOne.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;  
}


.open {
  background-image: url('../assets/butterfly/ButterflyStateOne.png');
}
.openClose {
  background-image: url('../assets/butterfly/ButterflyStateTwo.png');
}
.close {
  background-image: url('../assets/butterfly/ButterflyStateThree.png');
}
.closeOpen {
  background-image: url('../assets/butterfly/ButterflyStateTwo.png');
}
