.mobContainer {
  position: absolute;
  width: 5rem;
  height: 9rem;
  left: 0;
  top: 48vh;
  z-index: 5;
}

.container {
  position: absolute;
  width: 5rem;
  height: 9rem;
  left: 1.5rem;
  bottom: 1rem;
  z-index: 5;

  .img0,
  .img1,
  .img2,
  .img3,
  .img4,
  .img5 {
    width: 100%;
    height: 100%;
    transition: all 0.8s linear;
    opacity: 0;
    position: absolute;
  }

  .img0 {
    opacity: 1;
    z-index: 2;
    background: center/contain no-repeat url('../assets/bottle/Milk_0.png');
  }
  .img1 {
    z-index: 3;
    background: center/contain no-repeat url('../assets/bottle/Milk_1.png');
  }
  .img2 {
    z-index: 4;
    background: center/contain no-repeat url('../assets/bottle/Milk_2.png');
  }
  .img3 {
    z-index: 5;
    background: center/contain no-repeat url('../assets/bottle/Milk_3.png');
  }
  .img4 {
    z-index: 6;
    background: center/contain no-repeat url('../assets/bottle/Milk_4.png');
  }
  .img5 {
    z-index: 7;
    background: center/contain no-repeat url('../assets/bottle/Milk_5.png');
  }

  .face1,
  .face2 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  } 
}

.bubbleCont {
  position: absolute;
  width: 16rem;
  height: 5rem;
  left: 2.5rem;
  bottom: 11rem;
  z-index: 5;
  color: var(--blue-color);
  padding: 1rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;  
  text-align: center;
  background: center/contain no-repeat url('./../assets/Milk Jug Hover.png');
  opacity: 0;
  transition: opacity 0.8s linear;
}

.bubbleOpen {
  @extend .bubbleCont;
  opacity: 1;
}

.divider1,
.divider2 {
  position: absolute;
  bottom: -10%;
  left: -1.3rem;
  width: 7.5rem;
  height: 20rem;  
  transform: rotate(180deg);  
}

.divider1 {
  transition: all 2s linear;
  animation: splash 1.6s linear;
}

@keyframes splash {
  0% {
    left: -1.3rem;
  }
  25% {
    left: 0;
  }
  50% {
    left: 1rem;
  }
  75% {
    left: 0;
  }
  100% {
    left: -1.3rem;
  }
}

.divider2 {
  background-color: #407b4a;
  transition: all 1.5s linear;
}

@media (max-width: 800px) {
  .container {
    width: 4rem;
    height: 8rem;
    left: 1rem;
    .bottomContainer {
      width: 5rem;
      height: 5rem;
    }
  }

  .bubbleCont {
    left: 1rem;
    bottom: 10rem;
  }
}
