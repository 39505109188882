.visible {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 900;
  opacity: 1;
  transition: all 0.6s linear;
}

.unvisible {
  @extend .visible;
  opacity: 0;
  z-index: -2;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .image {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .image {
    width: auto;
    height: 100%;
  }
}

.logoContainer {
  position: absolute;
  width: 100%;
  height: 8rem;
  top: 1rem;
  left: 0;
  z-index: 2;
}

.mainChildContainer {
  position: relative;
  height: 10vh;
}

.mainText {
  position: absolute;
  left: 8rem;
  font-size: 2.2rem;
  top: 1.75rem;
}

.mainNewImage {
  position: absolute;
  object-fit: cover;
  left: -17.3rem;
  width: 43rem !important;
}

.alertContainer {
  position: absolute;
  width: 30rem;
  height: 13rem;
  left: calc(50% - 15rem);
  top: calc(50% - 6.5rem);
  z-index: 6;
  background-color: var(--white-color);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3rem;
  background: center/ contain no-repeat url('./../assets/Farm\ Experience.png');
  display: flex;
  flex-direction: column;  
  align-items: center;
  padding: 1.7rem 2rem;
}

.textTitle {
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--black-color);
  text-align: center;
}

.alertText {
  padding-top: 0.8rem;
  font-size: 1rem;
  color: var(--black-color);
  text-align: center;
}
.progressContainer {
  height: 6vmax;
  margin-top: 0.4rem;
}

.gotButton {
  width: 8.5rem;
  height: 2.4rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--white-color);
  background: center/ contain no-repeat
    url('./../assets/Enter\ Button\ Static.png');
  &:hover {
    background-image: url('./../assets/Enter\ Button\ Hover.png');
  }
}
