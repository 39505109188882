.container {
  position: absolute;  
  z-index: 3;
  border-radius: 1.6rem;
  background-color: var(--white-color);
  left: 66vw;
  top: 29vh;
  box-shadow: 0 0 0 3px var(--light-blue) inset;
 
  width: 3rem;
  opacity: 0;
  transition: max-height 0.4s, width 0.3s, opacity 0.5s linear; 
}

.container_open {
  @extend .container;
  z-index: 8;
  opacity: 1;
  width: 18rem;
  max-height: 16rem;
  .cowIcon {
    transition-delay: 0.15s;
    opacity: 1;
  }
}

.container_open_mobile {
  @extend .container_open; 
  width: 16rem;  
}

.infoIcon {
  position: absolute;
  width: 3.3rem;
  height: 3.3rem;   
  left: 0;
  top: 0;
  z-index: 2;   
  background: center / contain no-repeat url('./../assets/Hover\ i\ Icon.png');
}

.cowIcon {
  position: absolute;
  width: 12rem;
  height: 12rem; 
  opacity: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;  
  border-radius: 50%;
  right: -6.5rem;
  top: 10%;
  z-index: 2;
  transition: all 0.1s linear;
}

.textContainer {
  width: 13rem;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 300;
  color: var(--black-color);
  padding: 0.8rem 0.8rem 0.8rem 3.3rem;
  transition: opacity 0.2s linear;
  opacity: 0;
}

.textContainer_open {
  @extend .textContainer;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.textContainer_open_mobile {
  @extend .textContainer;
  width: 12rem;
  opacity: 1;
  transition: opacity 0.2s linear;
  font-size: 0.9rem;
  line-height: 1.1rem;
  padding: 0.6rem 0.7rem 0.6rem 3.3rem;
}

.shape {
  shape-outside: polygon(
    // 63% 56%, 80% 48%, 100% 45%, 100% 100%, 43% 99%, 44% 84%, 50% 69%
    61% 40%,
    78% 32%,
    100% 30%,
    100% 100%,
    39% 100%,
    40% 75%,
    46% 54%
  );
  clip-path: polygon(
    // 63% 56%, 80% 48%, 100% 45%, 100% 100%, 43% 99%, 44% 84%, 50% 69%
    61% 40%,
    78% 32%,
    100% 30%,
    100% 100%,
    39% 100%,
    40% 75%,
    46% 54%
  );
  float: right;
  width: 8.5rem;
  height: auto !important;  
  shape-margin: 0.5rem;
 
}

.close {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  right: 1.4rem;
  top: 0.4rem;
  z-index: 3;
  background: center / cover no-repeat url('./../assets/X.png'); 
}
