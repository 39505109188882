.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  z-index: 1;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.alertContainer {
  position: absolute;
  width: 27rem;
  height: 14rem;
  left: calc(50% - 13.5rem);
  top: calc(50% - 7rem);
  z-index: 6;
  background-color: var(--white-color);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3rem;
  background: center/ contain no-repeat url("./../assets/VideoCompletion.png");
}

.videoAlert {
  padding: 2rem 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mouseIcon {
  width: 4rem;
  height: 4rem;
  background: center/ contain no-repeat url("./../assets/Mouse\ Icon.png");
}

.mobileIcon {
  width: 4rem;
  height: 4rem;
  background: center/ contain no-repeat url("./../assets/Tap\ Icon.png");
}

.alertText {
  padding-top: 1rem;
  font-size: 1.3rem;
  line-height: 1.5rem;  
  color: var(--black-color);
  text-align: center;
}

.gotButton {
  width: 8.5rem;
  height: 2.4rem;
  margin-top: 1rem;  
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: var(--white-color);
  background: center/ contain no-repeat url("./../assets/Got\ It\ Static.png");
 }

.controlsButton {
  position: absolute;
  width: 9.5rem;
  height: 0.8rem;
  bottom: 2rem;
  left: calc(50% - 4.75rem);
  z-index: 5;
  border-radius: 0.4rem;
  background-color: var(--white-color);  
}

.voiceBut,
.muteBut {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  bottom: 2rem;
  left: 2rem;
  z-index: 5; 
}

.voiceBut {
  background: center/contain no-repeat url("./../assets/Sound_Off.png");
}

.muteBut {
  background: center/contain no-repeat url("./../assets/Sound_On.png");
}

@media (max-width: 600px) {
  .voiceBut,
  .muteBut {
    bottom: 9.5rem;
  }
}
