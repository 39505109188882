.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
}
.congratsContainer,
.playAllContainer {
  position: absolute;
  z-index: 15;
  background-color: var(--white-color);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3rem;
  background: center/ contain no-repeat
    url('./../assets/NextVideoPop-up.png');
}

.congratsContainer {
  width: 24rem;
  height: 18rem;
  left: calc(50% - 12rem);
  top: calc(50% - 9rem);
}

.playAllContainer {
  width: 20rem;
  height: 12rem;
  left: calc(50% - 10rem);
  top: calc(50% - 6rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.congrats {
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.congratsTitle {
  padding-top: 0.5rem;
  font-size: 2.3rem;
  font-weight: 900;
  color: var(--black-color);
}

.congratsText {
  padding-top: 1.2rem;
  width: 23rem;
  font-size: 1.2rem;
  color: var(--black-color);
  text-align: center;
}

.nextButton {
  width: 24rem;
  height: 2.4rem;
  margin-top: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 900;
  color: var(--white-color);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  &:hover {
  }
}

.homeButton {
  width: 11rem;
  height: 2.3rem;
  margin-top: 1.5rem; 
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 900;
  background: center/ contain no-repeat
    url('./../assets/Back\ to\ Explore\ Static.png');
  &:hover {
    background-image: url('./../assets/Back_to_Explore_Hover1.png');
  }
}

.close {
  position: absolute;
  width: 2rem;
  height: 2rem;
  right: 1.2rem;
  top: 1rem;
  z-index: 2;
  background: center / cover no-repeat url('./../assets/X_Icon.png');
}

@media (max-width: 800px) {
  .congrats {
    padding: 2.8rem 2rem;
  }
  .congratsContainer {
    width: 24rem;
    height: 17rem;
    left: calc(50% - 12rem);
    top: calc(50% - 8.5rem);
  }
  .nextButton {
    width: 22rem;
    height: 2.25rem;
    margin-top: 1.8rem;
    font-size: 0.9rem;
  }
  .homeButton {
    margin-top: 1rem;
    height: 2.1rem;
  }
  .close {   
    right: 1.8rem;
    top: 1rem;
    z-index: 2;
    background: center / cover no-repeat url('./../assets/X_Icon.png');
  }
}
